<template>
  <div class="container" v-title id="workBillStatus" :data-title="$t('i18n.workOrderProcess')">
    <div id="outer-title">{{ $t("i18n.workOrderProcess") }}</div>
    <div class="view-form">
      <div class="item" v-for="item in itemData" :key="item">
        <template v-if="!(
    isHidden &&
    (item.label == 'serviceStaff' || item.label == 'faultDescription')
  )
    ">
          <label>{{ $t("i18n." + item.label) }}：</label>
          <span :style="{ width: item.width }">
            {{ billStatusData[item.value] }}
          </span>
        </template>
      </div>
    </div>
    <div class="flex">
      <el-timeline>
        <el-timeline-item v-for="(item, index) in timeLine" :key="index" color="#34AEED" :timestamp="item.timestamp">
          {{ $t("i18n." + item.content) }}
        </el-timeline-item>
      </el-timeline>
      <div id="jlMap" style="margin-left: 10px;" :style="{ height: $defined.HEIGHT - 420 + 'px', width: '50%' }"></div>
      <video style="margin-left: 10px;" width="320" height="425" controls :src="videoPlayer"></video>
      <div style="width: 30%; height: 425px; margin-left: 10px;">
        <div @click="changeVideo(item)" v-for="(item, index) in billStatusData.vlog" :key="index" style="overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; cursor: pointer; padding-bottom: 10px;">{{ index + 1 }}.  <span
            class="content_video">https://scm.joylive.com/{{ item }}</span></div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
import BMapGL from "BMap";
import { jsonp } from "vue-jsonp";
import eleImg from "../../assets/pointMap/end.png";
import manImg from "../../assets/pointMap/start.png";
import startIcon from "../../assets/chart/start_icon.png";
import endIcon from "../../assets/chart/end_icon.png";
import personIcon from "../../assets/chart/person_icon.png";
export default {
  name: "WorkBillwbStatus",
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const state = reactive({
      videoPlayer: null,
      itemData: [
        { label: "orderNumber", value: "billNumber" },
        { label: "orderCategory", value: "billModelName" },
        { label: "workOrderStatus", value: "status" },
        { label: "serviceStaff", value: "faultPerson" },
        { label: "faultDescription", value: "faultDescription" },
        { label: "contractInformation", value: "faultPersonPhone" },
        { label: "equipmentNo", value: "elevatorNumber" },
        { label: "elevatorType", value: "eleType" },
        { label: "address", value: "alias", width: "444px" },
        { label: "maintenanceCompany", value: "maintainer", width: "444px" },
        { label: "maintenancePerson", value: "actor" },
        { label: "contractInformation", value: "manPhone" },
        { label: "maintenanceTimes", value: "monthNo" },
      ],
      billStatusData: {},
      timeLine: [],
      jlMap: null,
      isHidden: false,
    });

    const setTimestamp = () => {
      var timestamp = [
        "createTime",
        "acceptTime",
        "startTime",
        "completeTime",
        "jobCompleteTime",
        "jobCompleteTime",
      ];
      var content = [
        "created",
        "WAITING",
        "INHAND",
        "processed",
        "ACCOMPLISH",
        "end",
      ];
      timestamp.forEach(function (key, index) {
        // 处理时间线数据'
        if (state.billStatusData[key]) {
          var obj = {};
          obj.timestamp = state.billStatusData[key];
          obj.content = content[index];
          state.timeLine.push(obj);
        }
      });
    };

    const initMap = () => {
      var map = (state.jlMap = new BMapGL.Map("jlMap"));
      map.centerAndZoom(new BMapGL.Point(proxy.$point[0], proxy.$point[1]), 5);
      map.enableScrollWheelZoom(true);
      map.enableDragging();
      map.setMapStyleV2({ styleId: "2a67ddfa8dc55bbc44a2e69f0bb16966" });
      dealMapData();
    };

    const loadMapData = () => {
      var data = state.billStatusData;
      var elePosition = new BMapGL.Point(data.eleLng, data.eleLat);
      var tip = data.elevatorNumber + "/" + data.alias;
      var label = data.eleLat && data.eleLng ? tip : "电梯经纬度有误，请确认";
      let points = [elePosition];
      drawPoint(elePosition, eleImg, label);
      if (data.manLat && data.manLng) {
        var manPosition = new BMapGL.Point(data.manLng, data.manLat);
        var label1 = data.actor;
        drawPoint(manPosition, manImg, label1);
        var polyline = new BMapGL.Polyline([elePosition, manPosition], {
          strokeColor: "#E9212A",
          strokeStyle: "solid",
          strokeWeight: 1,
          strokeOpacity: 0.8,
        });
        state.jlMap.addOverlay(polyline);
        points.push(manPosition);
      }
      state.jlMap.setViewport(points);
    };
    const changeVideo = (item) => {
      state.videoPlayer = "https://scm.joylive.com/" + item
    }
    const drawPoint = (position, img, str) => {
      var icon = new BMapGL.Icon(img, new BMapGL.Size(30, 40));
      var marker = new BMapGL.Marker(position, { icon: icon });
      var label = new BMapGL.Label(str, {
        offset: new BMapGL.Size(20, -10),
      });
      label.setStyle(proxy.$config.labelStyle);
      marker.setLabel(label);
      state.jlMap.addOverlay(marker);
    };

    const dealMapData = () => {
      // 记录工单节点开始/结束时间
      var pointTimes = [];
      if (state.billStatusData.stopTime1) {
        for (let i = 1; i < 11; i++) {
          let startTime = state.billStatusData["continueTime" + i];
          let endTime =
            i === 10
              ? state.billStatusData.completeTime
              : state.billStatusData["stopTime" + (i + 1)];
          if (startTime && endTime) {
            pointTimes.push({
              startTime: startTime,
              endTime: endTime,
            });
          }
        }
      } else {
        pointTimes.push({
          startTime: state.billStatusData.startTime,
          endTime: state.billStatusData.completeTime,
        });
      }
      if (state.billStatusData.completeTime) {
        // 工单已处理,显示员工做工单的轨迹
        pointTimes.map((item) => {
          requestTrajectoryData(
            item.startTime,
            item.endTime,
            state.billStatusData.uid
          );
        });
      } else {
        // 工单未处理,显示员工当前位置与电梯位置的直线
        loadMapData();
      }
    };

    const requestTrajectoryData = (start, end, entity_name) => {
      var start_time = Date.parse(new Date(start)) / 1000;
      var end_time = Date.parse(new Date(end)) / 1000;
      var diffDay = (end_time - start_time) / 86400;
      if (diffDay > 1) {
        // 两个时间相隔超过24小时
        end_time = start_time + 86400;
      }
      let obj = {
        ak: "YkHAGQ6vMs45BFBixvX19ksRnGOWKBQ2",
        service_id: 143574,
        entity_name: entity_name,
        start_time: start_time,
        end_time: end_time,
        is_processed: 1,
      };
      let url = "https://yingyan.baidu.com/api/v3/track/gettrack";
      jsonp(url, obj).then((res) => {
        if (res.status === 0) {
          if (res.points.length > 0) {
            let points = [];
            res.points.map((pt) => {
              points.push(new BMapGL.Point(pt.longitude, pt.latitude));
            });
            setLineByMap(points);
          }
        } else {
          proxy.$defined.tip("员工定位存在问题，请联系管理员处理!");
        }
      });
    };

    const setLineByMap = (pts) => {
      drawPoint(pts[0], startIcon, "");
      var i = 0;
      var interval = setInterval(function () {
        if (i >= pts.length) {
          clearInterval(interval);
          return;
        }
        drawLine(pts[i], pts[i + 1]); //画线调用
        i = i + 1;
      }, 500);
      state.jlMap.setViewport(pts);
    };

    const drawLine = (PointArr, PointArrNext) => {
      if (PointArrNext != undefined) {
        var polyline = new BMapGL.Polyline([PointArr, PointArrNext], {
          strokeColor: "red",
          strokeWeight: 2,
          strokeOpacity: 1,
        });
        state.jlMap.addOverlay(polyline);
        addMarkerEnd(PointArrNext, "行走"); //添加图标
      } else {
        addMarkerEnd(PointArr, "终点"); //添加终点图标
      }
    };

    //添加行驶和终点图标
    var carMk;
    const addMarkerEnd = (point, name) => {
      var drivingPoint = new BMapGL.Icon(personIcon, new BMapGL.Size(32, 32), {
        offset: new BMapGL.Size(13, 33),
      });
      if (name == "行走") {
        if (carMk) {
          state.jlMap.removeOverlay(carMk);
        }
        carMk = new BMapGL.Marker(point, { icon: drivingPoint }); // 创建标注
        state.jlMap.addOverlay(carMk); // 将标注添加到地图中
      } else {
        state.jlMap.removeOverlay(carMk);
        drawPoint(point, endIcon, "");
      }
    };

    const init = async () => {
      let id = route.query.id;
      let { data } = await proxy.$api.maintenance.handbillsStatus(id);
      state.isHidden = data.billModelName.indexOf("保养") > -1;
      if (data.vlog) {
        data.vlog = data.vlog.split("=")
      }
      state.billStatusData = data;
      initMap();
      setTimestamp();
    };

    init();

    return {
      ...toRefs(state),
      changeVideo
    };
  },
};
</script>

<style lang="scss">
.content_video:hover {
  color: blue;
}

#workBillStatus {
  .view-form {
    background: $gray2_color;
    margin-bottom: 20px;

    label {
      width: 96px;
    }

    span {
      width: 174px;
    }
  }
}

@media screen and (max-width: 768px) {
  #workBillStatus {
    span {
      width: 200px !important;
    }
  }
}
</style>
